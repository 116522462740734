import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IFilterAssociationSocialWorkRequest, IFilterSocialWorkRequest, ISocialWork, ISocialWorkAssignRequest, ISocialWorkListFilterParams, ISocialWorkResponse, ISocialWorkSelectListRequest } from "../../api/Interfaces/configurations/ISocialWork";
import { IPostResult } from "../../api/Interfaces/managment/ICrudDescount";
import errorHandler from "../errorHandler";
import { selectListResponse } from "../interfaces/utils/IUtilDeclaration";

const GetSocialWorkList = async (params: IFilterSocialWorkRequest | {}): Promise<IApiResponse<ISocialWork[]>> => {
    try{
        const response = await api.getSocialWorks(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetSocialWorkSelect = async (params: ISocialWorkSelectListRequest | {}): Promise<IApiResponse<selectListResponse[]>> => {
    try{
        const response = await api.GetSocialWorkForSelect(params);
        return {
            data: {results: response.data, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetSocialWorkListByAssociation = async (associationId: number, params: IFilterAssociationSocialWorkRequest | {}): Promise<IApiResponse<any>> => {
    try{
        const response = await api.GetSocialWorksByAssociation(associationId, params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetSocialWorkById = async (socialWorkAccountId: number, accountId?: number): Promise<IApiResponse<ISocialWorkResponse>> => {
    try{
        const response = await api.getSocialWorkById(socialWorkAccountId, accountId);
        return {
            data: {results: response.data, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetInstructiveSocialWork = async (socialWorkAccountId: number): Promise<IApiResponse<string | null>> => {
    try{
        const response = await api.getInstructiveById(socialWorkAccountId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const AssignSocialWork = async (socialWorkAccountId: number, params: ISocialWorkAssignRequest): Promise<IApiResponse<IPostResult>> => {
    try{
        const response = await api.AssignSocialWorkAccount(socialWorkAccountId, params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditSocialWork = async (socialWorkAccountId: number, params: ISocialWorkAssignRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.updateAssingSocialWork(socialWorkAccountId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const UpdateAmountSocialWork = async (associationId: number, socialWorkAccountId: number, socialWorkPlanId: number, params: any): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateAmountSocialWork(associationId, socialWorkAccountId, socialWorkPlanId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateSocialWorkByAssociation = async (associationId: number, socialWorkId: number, params: ISocialWorkAssignRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.AssignSocialWorkByAssociation(associationId, socialWorkId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditSocialWorkByAssociation = async (associationId: number, accountId: number, obraSocialId: number, params: ISocialWorkAssignRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateSocialWorkByAssociation(associationId, accountId, obraSocialId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteSocialWork = async (socialWorkAccountId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.deleteAssingSocialWork(socialWorkAccountId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const AssingInstructiveForSocialWork = async (socialWorkAccountId: number, params: string | null): Promise<IApiResponse<void>> => {
    try{
        const response = await api.assingInstructive(socialWorkAccountId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const ReactivateAssignedPractice = async (socialWorkId: number, practiceId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.ReactivateAssignedPractice(socialWorkId, practiceId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const ReactivatePractice = async (practiceId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.ReactivatePractice(practiceId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
const UpdateAssignedPractice = async (socialWorkId: number, idPlan: number, practiceId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateAssignedPractice(socialWorkId, idPlan, practiceId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const ReactivateAssignedSocialWorkAccount = async (socialWorkId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.ReactivateAssignedSocialWorkAccount(socialWorkId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const ReactivateSocialWork = async (socialWorkId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.ReactivateSocialWork(socialWorkId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

export {
    GetSocialWorkList, 
    GetSocialWorkSelect, 
    GetSocialWorkListByAssociation, 
    GetSocialWorkById, 
    GetInstructiveSocialWork,
    AssignSocialWork,
    EditSocialWork,
    CreateSocialWorkByAssociation,
    EditSocialWorkByAssociation,
    DeleteSocialWork,
    UpdateAmountSocialWork,
    AssingInstructiveForSocialWork,
    ReactivateAssignedPractice,
    ReactivateAssignedSocialWorkAccount,
    ReactivatePractice,
    ReactivateSocialWork,
    UpdateAssignedPractice
}