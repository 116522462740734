import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, makeStyles, Collapse } from '@material-ui/core';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React from 'react';
import ReactSelect from 'react-select';
import api from "../../../../../api";
import { IPracticeExtended, PracticeAmountRowRequest } from "../../../../../api/Interfaces/configurations/IAmounts";
import { StatusCode } from '../../../../../enums';
import { IPracticeCheck, IPracticeSelected } from "../../../../../services/interfaces/Configurations/AssignAmountToPractice";
import { IDataToAssign, IErrorNotificationAmountAssociation, ILoaderSelect, IModalAmountForOs } from "../../../../../services/interfaces/Configurations/IModalAmoutForOs";
import { selectTypeFormat } from "../../../../../services/interfaces/utils/IUtilDeclaration";
import { GetSocialWorkSelect, UpdateAmountSocialWork } from '../../../../../services/services/ConfigurationSocialWork';
import { GetPractices } from "../../../../../services/services/Practices";
import GeneralModalCrud from '../../../../utils/GeneralModalCrud';
import GeneralSimpleList from "../../../../utils/GeneralSimpleList";
import { _DEFAULT_VALUES_ModalAmountForOs } from "../../defaultValues";

const customStyles = makeStyles(() => ({
    contAmoutModal: {
        overflow: "visible",
    }
}))

const defaultValueSelectPlan = { id: null, nombre: "" };

const ModalAmountForOs: React.FC<IModalAmountForOs> = ({
    associationId,
    openModal,
    titleOfModal,
    modal,
    setLoadingActive,
    CloseModal,
    MessageNotification }) => {
    const classStyle = customStyles();
    const [practiceList, setPracticeList] = React.useState<IPracticeExtended[]>([]);
    const [socialWorkList, setSocialWorkList] = React.useState<selectTypeFormat[]>([]);
    const [loaderSelect, setLoaderSelect] = React.useState<ILoaderSelect>(_DEFAULT_VALUES_ModalAmountForOs._LOADER_SELECT);
    const [errors, setErrors] = React.useState<IErrorNotificationAmountAssociation>(_DEFAULT_VALUES_ModalAmountForOs._ERROR_NOTIFICATION);
    const [practiceSelected, setPracticeSelected] = React.useState<IPracticeSelected>({});
    const [dataToAssign, setDataToAssing] = React.useState<IDataToAssign>(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);
    const [plansList, setPlansList] = React.useState([]);
    const [selectPlan, setSelectPlan] = React.useState(defaultValueSelectPlan);

    React.useEffect(() => {
        SocialWorklist();
    }, []);

    React.useEffect(() => {
        if (selectPlan.id) {
            PracticeList(Number(dataToAssign.socialWorkId))
        }
    }, [selectPlan]);

    async function SocialWorklist() {
        try {
            setLoaderSelect({ ...loaderSelect, socialWork: true });
            const params = {
                asignadas: true,
                confirmada: true
            }
            const { status, data } = await GetSocialWorkSelect(params);
            if (status === StatusCode.Ok) {
                const socialWorks: selectTypeFormat[] = data.results.map(item => {
                    return { value: item.id, label: item.nombre }
                });
                setSocialWorkList(socialWorks);
            }
        } catch (err: any) {
            MessageNotification(err.response.data.errores, "error")
        } finally {
            setLoaderSelect({ ...loaderSelect, socialWork: false });
        }
    }
    async function PracticeList(socialWorkId: number) {
        try {
            setLoadingActive(true);
            let { data, status } = await GetPractices(socialWorkId, Number(selectPlan.id), true, false, false, 1, 20);
            if (status === StatusCode.Ok) {
                const practiceList: IPracticeSelected = data.results.reduce((acc, actualValue) => {
                    return {
                        ...acc,
                        [actualValue.id]: {
                            idPractice: +actualValue.id,
                            checked: false,
                            amount: 0
                        }
                    }
                }, {});
                setPracticeSelected(practiceList);
                setPracticeList(data.results);

            }
        } catch (err: any) {
            MessageNotification(err.response.data.errores, "error")
        } finally {
            setLoadingActive(false);
        }
    }
    async function GetSocialWorksPlan(socialWorkId: number) {
        try {
            setLoadingActive(true);
            const plansData = await api.GetPlansBySocialWorkPlansAccounts(socialWorkId, false);

            let planList = plansData.data.map(plan => {
                return { value: plan.id, label: plan["nombre"] }
            });

            if(planList.length === 0) {
                MessageNotification("No se encontraron Planes para la obra social seleccionada.", 'info');
            }
            setPlansList(planList)
        }
        catch (err: any) {
            MessageNotification(err.response.data.errores ?? "Ocurrió un error.", 'error');
        }
        finally {
            setLoadingActive(false);
        }
    }

    function GetPracticesBySocialWorkSelected(e) {
        if (e !== null) {
            setDataToAssing({
                ...dataToAssign,
                socialWorkId: +e.value
            });
            GetSocialWorksPlan(e.value);

            //al seleccionar otra obra social se settean los valores de plan y date a su estado default
            setPlansList([])
            setSelectPlan(defaultValueSelectPlan)
            setDataToAssing(prevState => ({ ...prevState, date: _DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN.date }))
            setErrors(prevState => ({...prevState, socialWorkId: false}))
        }
    }
    function HandleChangeDate(dateSelected) {
        setDataToAssing({
            ...dataToAssign,
            date: dateSelected
        })
        setErrors(prevState => ({...prevState, date: false}))
    }
    function ValidateInputs() {
        let objError: IErrorNotificationAmountAssociation = _DEFAULT_VALUES_ModalAmountForOs._ERROR_NOTIFICATION;
        const practiceList: IPracticeCheck[] = Object.values(practiceSelected);
        const nonePracticeIsSelected = practiceList.every((practice: any) => practice.checked === false);

        if (dataToAssign.socialWorkId === null) {
            MessageNotification("El campo 'Obra Social' es obligatorio.", "error");
            setErrors(prevState => ({...prevState, socialWorkId: true}))
            return false;
        }
        if (selectPlan.id === null) {
            MessageNotification("El campo 'Planes' es obligatorio.", "error");
            setErrors(prevState => ({...prevState, planId: true}))
            return false;
        }
        if (dataToAssign.date === null) {
            MessageNotification("El campo 'fecha' es obligatorio.", "error");
            setErrors(prevState => ({...prevState, date: true}))
            return false;
        }
        if (nonePracticeIsSelected) {
            MessageNotification("Deberá seleccionar al menos una práctica.", "error");
            practiceList.forEach((practice: IPracticeCheck) => {
                objError[practice.idPractice] = true;
            });
            setErrors(objError);
            return false;
        }
        if (!nonePracticeIsSelected) {
            const practiceSelectedWithCheck = practiceList.filter((practice: IPracticeCheck) => practice.checked === true);
            const valueSelectedWithZero = practiceSelectedWithCheck.some((practice: IPracticeCheck) => practice.amount <= 0 || practice.amount > 9999999);
            if (valueSelectedWithZero) {
                MessageNotification("El monto asignado deberá ser mayor a cero y menor a 9999999.", "error");
                practiceSelectedWithCheck.forEach((practice: IPracticeCheck) => {
                    if (practice.amount <= 0 || practice.amount > 9999999) {
                        objError[practice.idPractice] = true;
                    }
                });
                setErrors(objError);
                return false;
            }
        }
        return true;
    }
    function HandleSubmit() {
        if (ValidateInputs()) {
            AssingAmountSocialWorkAccount();
        }
    }
    async function AssingAmountSocialWorkAccount() {
        try {
            setLoadingActive(true);
            const filteredPracticeList = Object.values(practiceSelected).filter((practice: IPracticeCheck) => practice.checked);
            const request: PracticeAmountRowRequest[] = filteredPracticeList.map((practice: IPracticeCheck) => ({
                id: practice.idPractice,
                fecha: dataToAssign.date as Date,
                monto: practice.amount
            }))
            const { status } = await UpdateAmountSocialWork(+associationId, Number(dataToAssign.socialWorkId), Number(selectPlan.id), request);
            if (status === StatusCode.Ok) {
                CloseModal("close", "massiveEditAmount");
                setDataToAssing(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);
                setErrors(_DEFAULT_VALUES_ModalAmountForOs._ERROR_NOTIFICATION);
                setPracticeSelected({});
                MessageNotification("Montos actualizados correctamente.", "success");
            }
        } catch (err: any) {
            MessageNotification(err.message ?? err.response.data.errores, "error");
        }
        finally {
            setLoadingActive(false);
        }
    }
    function HandleCheckAllPractices(e) {
        const allPracticeChecked: IPracticeCheck | {} = practiceList.reduce((acc, actualValue) => {
            return {
                ...acc,
                [actualValue.id]: {
                    ...practiceSelected[actualValue.id],
                    checked: e.target.checked,
                    amount: e.target.checked ? +practiceSelected[actualValue.id]?.amount : 0
                }
            }
        }, {});
        setPracticeSelected(allPracticeChecked);
    }
    function HandleAssignAmount(e) {
        setPracticeSelected({
            ...practiceSelected,
            [e.target.name]: {
                ...practiceSelected[e.target.name],
                amount: +e.target.value
            }
        });
    }
    function HandleSelectPractice(e) {
        setPracticeSelected({
            ...practiceSelected,
            [e.target.name]: {
                idPractice: +e.target.name,
                checked: e.target.checked,
                amount: e.target.checked ? +practiceSelected[e.target.name]?.amount : 0
            }
        })
    }
    return (
        <GeneralModalCrud
            openModal={openModal}
            HandleCloseModal={() => CloseModal("close", modal)}
            HandleSubmit={HandleSubmit}
            titleOfModal={titleOfModal}
            styles={`${classStyle.contAmoutModal}`}
        >
            <>
                <div style={{ position: "relative", margin: "auto", marginTop: 7, minWidth: "280px" }}>
                    <label htmlFor="my-select" className="customLabelSelect">Obras Sociales:</label>
                    <ReactSelect
                        placeholder="Obras Sociales..."
                        options={socialWorkList}
                        isLoading={loaderSelect.socialWork}
                        className={errors.socialWorkId ? "errorNotificationInput" : ""}
                        onChange={(e) => GetPracticesBySocialWorkSelected(e)}
                        styles={{
                            control: (s) => ({ ...s, maxWidth: 300 }),
                            menu: (provided) => ({ ...provided, zIndex: 20 })
                        }}
                    />
                </div>
                <Collapse in={plansList !== null && plansList.length > 0} style={{ margin: "auto", marginTop: 5, minWidth: "280px" }}>
                    <div style={{ position: "relative" }}>
                        <label htmlFor="my-select" className="customLabelSelect">Planes:</label>
                        <ReactSelect
                            placeholder="Planes..."
                            options={plansList}
                            className={errors.planId ? "errorNotificationInput" : ""}
                            onChange={(e: any) => {
                                setSelectPlan(prevState => ({ ...prevState, id: e?.value, nombre: e?.label }));
                                setErrors(prevState => ({...prevState, planId: false}));
                            }}
                            styles={{
                                control: (s: any) => ({ ...s, maxWidth: 300 }),
                                menu: (provided) => ({ ...provided, zIndex: 20 })
                            }}
                            value={selectPlan.id ? { value: selectPlan.id, label: selectPlan.nombre } : null}
                        />
                    </div>
                    <div style={{ position: "relative", marginTop: 20 }}>
                        <label className="customLabelSelect">Fecha:</label>
                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ minWidth: 280 }}
                                autoOk
                                className='size-date-picke-filter'
                                inputVariant='outlined'
                                InputAdornmentProps={{ position: 'end' }}
                                variant='inline'
                                name='month'
                                error={errors.date}
                                format="dd/MM/yyyy"
                                placeholder='Fecha'
                                value={dataToAssign.date}
                                onChange={(date) => HandleChangeDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Collapse>
                {selectPlan.id !== null && //renderizar cuando traiga las practicas segun el plan seleccionado
                    <GeneralSimpleList
                        dataToRender={practiceList}
                        isLoaderActive={false}
                        paginated={false}
                        hasMaxHeight={true}
                    >
                        <Table aria-label='spanning table' className="cont-AmountPracticeList">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='letter-title-table c-text-center'>
                                        <Checkbox
                                            checked={Object.values(practiceSelected).length > 0 && Object.values(practiceSelected).every((practice: IPracticeCheck) => practice.checked)}
                                            onChange={(e) => HandleCheckAllPractices(e)}
                                        />
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-center'>
                                        <b>Código - Práctica</b>
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-center'>
                                        <b>Monto</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {practiceList.map((item: IPracticeExtended) => (
                                    <TableRow key={item.id}>
                                        <TableCell className='letter-title-table c-text-center p-cell'>
                                            <Checkbox
                                                checked={practiceSelected[item.id]?.checked ?? false}
                                                name={`${item.id}`}
                                                onChange={(e) => HandleSelectPractice(e)}
                                            />
                                        </TableCell>
                                        <TableCell className='letter-title-table c-text-center p-cell'>
                                            {item.codigo ?? "No asignado"} - {item.alias ?? item.nombre}
                                        </TableCell>
                                        <TableCell className='letter-title-table c-text-center p-cell'>
                                            <TextField
                                                value={practiceSelected[item.id]?.amount ?? 0}
                                                type='number'
                                                size="small"
                                                disabled={practiceSelected[item.id]?.checked === false || !practiceSelected[item.id]}
                                                name={`${item.id}`}
                                                error={errors[item.id]}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => HandleAssignAmount(e)}
                                                InputProps={{
                                                    inputProps: { max: 9999999, min: 0 },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AttachMoneyOutlinedIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </GeneralSimpleList>}
            </>

        </GeneralModalCrud>
    )
}

export default ModalAmountForOs;