import { Collapse, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import api from '../../../../api';
import { StatusCode } from '../../../../enums';
import { IDataToAssign, ILoaderSelect, IModalAmountForOs } from "../../../../services/interfaces/Configurations/IModalAmoutForOs";
import { selectTypeFormat } from "../../../../services/interfaces/utils/IUtilDeclaration";
import { GetSocialWorkSelect } from '../../../../services/services/ConfigurationSocialWork';
import { GetPractices } from "../../../../services/services/Practices";
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import { _DEFAULT_VALUES_ModalAmountForOs } from "../defaultValues";

interface ValueState {
  id: string | null;
  nombre: string | null;
  alias: string | null;
  aliasReporte: string | null;
}
const defaultValueSelectPlan = { id: null, nombre: "" };
const defaultValuePracticaAndALias = { id: null, nombre: null, alias: null, aliasReporte: null };

const ModalPracticeForAssociation: React.FC<IModalAmountForOs> = ({
  associationId,
  openModal,
  titleOfModal,
  modal,
  setLoadingActive,
  CloseModal,
  MessageNotification }) => {
  const [socialWorkList, setSocialWorkList] = useState<selectTypeFormat[]>([]);
  const [loaderSelect, setLoaderSelect] = useState<ILoaderSelect>(_DEFAULT_VALUES_ModalAmountForOs._LOADER_SELECT);
  const [dataToAssign, setDataToAssing] = useState<IDataToAssign>(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);

  const [notAssignPracticeList, setNotassignPracticeList] = useState([{}]);
  const [newValue, setNewValue] = useState<ValueState>(defaultValuePracticaAndALias);
  const [plansList, setPlansList] = React.useState([]);
  const [selectPlan, setSelectPlan] = React.useState(defaultValueSelectPlan);

  React.useEffect(() => {
    SocialWorklist();
  }, []);

  React.useEffect(() => {
    if (selectPlan.id) {
      PracticeList()
    }
  }, [selectPlan]);

  async function SocialWorklist() {
    try {
      setLoaderSelect({ ...loaderSelect, socialWork: true });
      const params = {
        asignadas: true,
        confirmada: true
      }
      const { status, data } = await GetSocialWorkSelect(params);
      if (status === StatusCode.Ok) {
        const socialWorks: selectTypeFormat[] = data.results.map(item => {
          return { value: item.id, label: item.nombre }
        });
        setSocialWorkList(socialWorks);
      }
    } catch (err: any) {
      MessageNotification(err.response.data.errores, "error")
    } finally {
      setLoaderSelect({ ...loaderSelect, socialWork: false });
    }
  }

  async function GetSocialWorksPlan(socialWorkId: number) {
    try {
      setLoadingActive(true);
      const plansData = await api.GetPlansBySocialWorkPlansAccounts(socialWorkId, false);

      let planList = plansData.data.map(plan => {
        return { value: plan.id, label: plan["nombre"] }
      });

      if (planList.length === 0) {
        MessageNotification("No se encontraron Planes para la obra social seleccionada.", 'info');
      }

      setPlansList(planList)
    }
    catch (err: any) {
      MessageNotification(err.response.data.errores ?? "Ocurrió un error.", 'error');
    }
    finally {
      setLoadingActive(false);
    }
  }

  async function PracticeList() {
    try {
      setLoadingActive(true);
      const { status, data } = await api.getPracticesBySocialWorkAndPlan(Number(dataToAssign.socialWorkId), Number(selectPlan.id), true, false, false, 1, 20, true);
      if (status === StatusCode.Ok) {
        let list = data.resultados.map(item => {
          return {
            value: item.id,
            label: item["nombre"],
          }
        });
        setNotassignPracticeList(list);
      }
    } catch (err: any) {
      MessageNotification(err.response.data.errores, "error")
    } finally {
      setLoadingActive(false);
    }
  }

  function GetPlanBySocialWorkSelected(e) {
    if (e !== null) {
      setDataToAssing({
        ...dataToAssign,
        socialWorkId: +e.value
      });
      GetSocialWorksPlan(Number(e.value));

      //si cambio de obra social, setteo los valores por defecto
      setPlansList([])
      setSelectPlan(defaultValueSelectPlan)
      setNewValue(defaultValuePracticaAndALias)
    }
  }
  function HandlePlan(e) {
    if (e !== null) {
      setSelectPlan(prevState => ({ ...prevState, id: e?.value, nombre: e?.label }));
      
      //setteo pratica y alias en null
      setNewValue(defaultValuePracticaAndALias);
    }
  }
  function ValidateInputs() {
    if (dataToAssign.socialWorkId === null) {
      MessageNotification("El campo 'Obra Social' es obligatorio.", "error");
      return false;
    }
    if (selectPlan.id === null) {
      MessageNotification("El campo 'Planes' no puede estar vacío.", 'error');
      return false;
    }
    if ((newValue.alias !== null || newValue.aliasReporte !== null)) {
      if ((newValue.aliasReporte === null || newValue.alias === null)) {
        MessageNotification("Deben completarse ambos campos para asignar un alias a la práctica.", 'error');
        return false;
      }
    }
    if (newValue.aliasReporte !== null && newValue.aliasReporte.length > 4) {
      MessageNotification("El Alias del reporte no debe de ser mayor a 4 caracteres.", 'error');
      return false;
    }
    if (newValue.id === null) {
      MessageNotification("El campo 'Practica' no puede estar vacío.", 'error');
      return false;
    }
    return true;
  }
  function HandleSubmit() {
    if (ValidateInputs()) {
      AssingPracticeSocialWorkAccount();
    }
  }
  async function AssingPracticeSocialWorkAccount() {
    try {
      setLoadingActive(true);
      let bodyParams = {
        practicaId: newValue.id,
        practicaAlias: newValue.alias,
        practicaAliasReporte: newValue.aliasReporte
      }
      const response = await api.AssignPracticeForOs(dataToAssign.socialWorkId, selectPlan.id, bodyParams);
      if (response.status === StatusCode.Ok) {
        CloseModal("close", "massiveEditAmount");
        setDataToAssing(_DEFAULT_VALUES_ModalAmountForOs._DATA_TO_ASSIGN);
        MessageNotification("Práctica Asociadas correctamente.", "success");
      }
    } catch (err: any) {
      MessageNotification(err.response.data.errores, "error");
    } finally {
      setNewValue({
        id: null,
        nombre: null,
        alias: null,
        aliasReporte: null
      });
      setLoadingActive(false);
    }
  }
  return (
    <GeneralModalCrud
      openModal={openModal}
      HandleCloseModal={() => CloseModal("close", modal)}
      HandleSubmit={HandleSubmit}
      titleOfModal={titleOfModal}
      styles="cont-default-modal"
    >
      <>
        <div style={{ position: "relative", margin: "auto", minWidth: "280px" }}>
          <label htmlFor="my-select" className="customLabelSelect">Obras Sociales:</label>
          <ReactSelect
            placeholder="Obras Sociales..."
            options={socialWorkList}
            isLoading={loaderSelect.socialWork}
            onChange={(e) => GetPlanBySocialWorkSelected(e)}
            styles={{
              control: (s: any) => ({ ...s, maxWidth: 300 }),
              menu: (provided) => ({ ...provided, zIndex: 20 })
            }}
          />
        </div>
        <Collapse in={plansList !== null && plansList.length > 0} style={{ margin: "auto", marginTop: 5, minWidth: "280px" }}>
          <div style={{ position: "relative" }}>
            <label htmlFor="my-select" className="customLabelSelect">Planes:</label>
            <ReactSelect
              placeholder="Planes..."
              options={plansList}
              onChange={(e: any) => HandlePlan(e)}
              styles={{
                control: (s: any) => ({ ...s, maxWidth: 300 }),
                menu: (provided) => ({ ...provided, zIndex: 20 })
              }}
              value={selectPlan.id ? { value: selectPlan.id, label: selectPlan.nombre } : null}
            />
          </div>
        </Collapse>
        <Collapse in={selectPlan.id !== null} style={{ margin: "auto", marginTop: 5, width: "280px" }}>
          <div style={{ position: "relative" }}>
            <label htmlFor="my-select" className="customLabelSelect">Prácticas:</label>
            <ReactSelect
              placeholder="Prácticas"
              options={notAssignPracticeList}
              onChange={(e) => setNewValue({
                ...newValue,
                id: e !== null ? e.value : null,
                nombre: e !== null ? e.label : null,
              })}
              styles={{
                control: (s: any) => ({ ...s, maxWidth: 300 }),
                menu: (provided) => ({ ...provided, zIndex: 20 })
              }}
              value={newValue.id ? {value: newValue.id, label: newValue.nombre} : null}
            />
          </div>
          <TextField
            size='small'
            id='alias'
            label='Alias Reporte'
            style={{ width: "100%", marginTop: 7 }}
            value={newValue.aliasReporte ? newValue.aliasReporte : ""}
            onChange={(e) => {
              setNewValue({
                ...newValue,
                aliasReporte: e.target.value === "" ? null : e.target.value
              });
            }}
          />
          <TextField
            size='small'
            id='alias'
            label='Alias'
            style={{ width: "100%" }}
            value={newValue.alias ? newValue.alias : ""}
            onChange={(e) => {
              setNewValue({
                ...newValue,
                alias: e.target.value === "" ? null : e.target.value
              });
            }}
          />
        </Collapse>
      </>
    </GeneralModalCrud>
  )
}

export default ModalPracticeForAssociation;